import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type BuildIdType = { buildId: string | undefined };

const initialState: BuildIdType = {
  buildId: undefined,
};

export const buildId = createSlice({
  name: "buildId",
  initialState,
  reducers: {
    updateBuildId: (state, action: PayloadAction<string>) => {
      const newState = state;
      newState.buildId = action.payload;
      return newState;
    },
  },
});

export const buildIdAction = buildId.actions;

export default buildId.reducer;
