import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers } from "redux";
import sportsBookReducer from "./sportsBook";
import toolsSportCountsReducer from "./toolsSportCounts";
import constantsReducer from "./constants";
import localStoreReducer from "./localStore";
import betCacheReducer from "./betCache";
import gameLiveDataReducer from "./gameLiveData";
import gameDataCacheReducer from "./gameDataCache";
import trackBetModalReducer from "./trackbet";
import buildIdReducer from "./buildId";

const rootReducer = combineReducers({
  sportsBookReducer,
  toolsSportCountsReducer,
  constantsReducer,
  betCacheReducer,
  gameLiveDataReducer,
  gameDataCacheReducer,
  localStoreReducer,
  trackBetModalReducer,
  buildIdReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
